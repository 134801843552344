import {
  useEffect
} from 'react';
import {
  ELSPropsFromModalService,
  ELSWithModalService
} from '@els/els-component-modal-react';
import { compose } from 'recompose';
import {
  useLocation
} from 'react-router';

export type LocationChangeHandlerPropsOnly = {}

export type LocationChangeHandlerProps = ELSPropsFromModalService;
const LocationChangeHandler = (props: LocationChangeHandlerProps) => {

  const location = useLocation();

  useEffect(() => {
    props.modalService.closeAllModal();
  }, [location]);

  return null;
};

const enhancers = [
  ELSWithModalService
];

export default compose<null, LocationChangeHandlerPropsOnly>(...enhancers)(LocationChangeHandler);
