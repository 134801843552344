import { get } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { fetchCrosswalkUser } from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import { SystemType } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import { AppContextState } from './AppProvider.component';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import {
  fetchAppLinkData,
  fetchHashLink
} from '../../apis/eols-app-link/eols-app-link.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { getDefaultActionProps } from './app-state-selectors.utilities';
import {
  fetchGroupedFeatureFlags,
  fetchGroupFeatureFlag,
  postGroupFeatureFlag
} from '../../apis/eols-features-api/eols-features-api.utilities';
import {
  getActiveABTestFlags,
  getRandomABTestFlavor
} from '../../utilities/featureFlag.utilities';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { fetchCourseSection as fetchCourseSectionService } from '../../apis/eols-course-crud/eols-course-crud.utilities';
import { AppState } from './app-state.types';

const fileName = 'app.state.actions.ts';
export const trackAction = (props: {
  appState: AppState;
  actionProps: AnalyticsActionProps;
}) => {
  const defaultActionProps = getDefaultActionProps(props.appState);
  const actionProps = scrubProps(props.actionProps.props);
  const _props = {
    ...defaultActionProps,
    ...actionProps
  };
  ELSLoggingService.info(fileName, `New Relic PageAction: ${props.actionProps.action}, ${JSON.stringify(_props)}`);
  if (!window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(props.actionProps.action, _props);
};

const requestStart = (props: { setAppState: AppContextState['setAppState'] }) => {
  props.setAppState((prevState) => {
    return {
      ...prevState,
      pendingRequestCount: prevState.pendingRequestCount + 1
    };
  });
};

const requestEnd = (setAppState: AppContextState['setAppState']) => {
  setAppState((prevState) => {
    return {
      ...prevState,
      pendingRequestCount: prevState.pendingRequestCount - 1
    };
  });
};

const handleRequestError = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  error: Error;
  action: string;
  isTrackRequestCount: boolean;
}) => {

  const {
    error,
    action,
    isTrackRequestCount,
    setAppState
  } = props;

  const url = get(error, 'config.url', '');
  const method = get(error, 'config.method', '');
  const status = get(error, 'response.status', '');
  if (status === 400) {
    // Likely 400 error here due to overloaded headers
    removeCookies();
  }

  trackAction({
    appState: props.appState,
    actionProps: {
      action: AnalyticsAction.API_ERROR,
      props: {
        status,
        action,
        url,
        method
      }
    }
  });

  if (isTrackRequestCount) {
    requestEnd(setAppState);
  }

  return Promise.reject(error);
};
export const fetchEvolveUsersAction = (props: {
  useCache: boolean;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  userId: string;
}) => {
  const {
    useCache,
    appState,
    setAppState,
    userId
  } = props;

  if (useCache && appState.evolveUser) {
    return Promise.resolve(appState.evolveUser);
  }
  requestStart({ setAppState });
  return fetchCrosswalkUser(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          evolveUser: crosswalkUser
        };
      });
      requestEnd(setAppState);
      return crosswalkUser;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchCrosswalkUser',
      isTrackRequestCount: true
    }));
};

export const fetchAppLinkDataAction = (props: {
  appLinkId: string;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {
  const {
    appLinkId,
    setAppState,
    appState
  } = props;

  requestStart({ setAppState });
  return fetchAppLinkData(appLinkId)
    .then((appLinkData) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          appLinkData,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return appLinkData;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchAppLinkDataAction',
      isTrackRequestCount: true
    }));
};

export const fetchHashLinkAction = (props: {
  linkHash: string;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {
  const {
    linkHash,
    appState,
    setAppState,
  } = props;

  requestStart({ setAppState });
  return fetchHashLink(linkHash)
    .then((hashLinkDto) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          appLinkCookies: hashLinkDto,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return hashLinkDto;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchHashLinkAction',
      isTrackRequestCount: true
    }));

};

const postGroupFeatureFlagAction = (props: {
  eolsApp: string;
  featureName: string;
  group: string;
  featureValue: string;
}) => {
  const {
    eolsApp,
    featureName,
    group,
    featureValue
  } = props;
  return postGroupFeatureFlag(eolsApp, featureName, group, featureValue).then(() => {
    return {
      createdAt: null,
      eolsApp,
      featureName,
      featureValue,
      group,
      updatedAt: null
    };
  });
};

const fetchABFlavorAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  abTestFlag: FeatureFlagsGroupedDto;
}) => {
  const {
    appState,
    setAppState,
    abTestFlag
  } = props;
  const abTestFlavor = props.appState.abTestFlavors.find((flag) => {
    return flag.featureName === abTestFlag.featureName;
  });
  if (abTestFlavor) {
    return Promise.resolve(abTestFlavor);
  }
  requestStart({ setAppState });
  const app = `STUDENT_STUDY_${abTestFlag.featureName}`;
  const key = abTestFlag.featureName;
  return fetchGroupFeatureFlag(app, key, appState.courseSectionId)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        const newFlavor = getRandomABTestFlavor(abTestFlag);
        if (!newFlavor) {
          ELSLoggingService.error(fileName, `AB test config missing for flag: ${key}`);
          return Promise.resolve({
            createdAt: null,
            eolsApp: app,
            featureName: key,
            featureValue: null,
            group: appState.courseSectionId,
            updatedAt: null
          });
        }
        return postGroupFeatureFlagAction({
          eolsApp: app,
          featureName: key,
          group: appState.courseSectionId,
          featureValue: newFlavor
        });
      }
      return Promise.reject(error);
    })
    .then((response) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          pendingRequestCount: prevState.pendingRequestCount - 1,
          abTestFlavors: [
            ...prevState.abTestFlavors,
            response
          ]
        };
      });
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchABFlavor',
      isTrackRequestCount: true

    }));
};

export const fetchAllAppFeatureFlagsAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {

  const {
    setAppState,
    appState
  } = props;

  if (appState.featureFlagsGrouped) {
    return Promise.resolve(appState.featureFlagsGrouped);
  }
  requestStart({ setAppState });
  return fetchGroupedFeatureFlags().then((response) => {
    setAppState((prevState) => {
      return {
        ...prevState,
        featureFlagsGrouped: response,
        pendingRequestCount: prevState.pendingRequestCount - 1
      };
    });
    const promises = [];
    const activeABTests = getActiveABTestFlags(response, appState.courseSectionId);

    if (activeABTests && activeABTests.length) {
      activeABTests.forEach((activeABTest) => {
        promises.push(
          fetchABFlavorAction({
            abTestFlag: activeABTest,
            appState,
            setAppState
          })
        );
      });
    }

    if (promises.length) {
      return Promise.all(promises).then(() => {
        return response;
      });
    }

    return response;
  })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchAllAppFeatureFlagsAction',
      isTrackRequestCount: true

    }));
};

export const fetchCourseSectionAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  courseSectionId: string;
  useCache: boolean;
}) => {
  const {
    appState,
    setAppState,
    courseSectionId,
    useCache
  } = props;
  if (!courseSectionId) {
    return Promise.resolve(null);
  }
  if (useCache && appState.currentCourse) {
    return Promise.resolve(appState.currentCourse);
  }
  requestStart({ setAppState });
  return fetchCourseSectionService(courseSectionId)
    .then((response) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          currentCourse: response,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchCourseSectionAction',
      isTrackRequestCount: true
    }));
};
