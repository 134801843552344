import {
  AppAction,
  Application
} from '../apis/eols-app-link/eols-app-link.constants';
import { AppLinkRedirectErrorMessage } from '../pages/app-link-redirect/app-link-redirect.constants';

export enum AnalyticsAction {
  LOGIN_LAUNCH = 'LOGIN_LAUNCH',
  APP_LINK_LAUNCH = 'APP_LINK_LAUNCH',
  APP_LINK_ERROR = 'APP_LINK_ERROR',
  APP_LINK_OUT = 'APP_LINK_OUT',
  API_ERROR = 'API_ERROR',
  JS_ERROR = 'JS_ERROR',
}

export type AnalyticsActionProps = {
  action: AnalyticsAction.LOGIN_LAUNCH;
  props: null;
} | {
  action: AnalyticsAction.API_ERROR;
  props: {
    status: string;
    action: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_ERROR;
  props: {
    type: AppLinkRedirectErrorMessage;
    status: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_OUT
    | AnalyticsAction.APP_LINK_LAUNCH;
  props: {
    linkType: AppAction;
    srcApp: Application;
    targetApp: Application;
  };
} | {
  action: AnalyticsAction.JS_ERROR;
  props: {
    errorName: string;
    errorMessage: string;
    errorStack: string;
  };
};

export type AdobeAnalyticsPageData = {
  visitor: {
    accessType: string;
    loginStatus: string;
    loginSuccess: string;
    loginFailure: string;
    accountId: string;
    accountName: string;
    ipAddress: string;
    userId: string;
    departmentId: string;
    departmentName: string;
    usagePathInfo: string;
  };
  page: {
    businessUnit: string;
    environment: string;
    productName: string;
    language: string;
    name: string;
    type: string;
    testId: string;
    channel: string;
    loadTime: number;
    loadTimestamp: string;
    analyticsPagename: string;
    sectionName: string;
    lastTrackedPage: string;
    cmsName: string;
    noTracking: string;
    eventName: string;
  };
  education: {
    courseId: string;
    courseName: string;
    isbn: string;
    assignmentId: string;
    assignmentName: string;
    assignmentType: string;
    moduleId: string;
    moduleName: string;
    moduleType: string;
    studentId: string;
    instructorId: string;
    semester: string;
    beforeAfterDueDate: string; // date compared to assignment due date in the format "due date +||- Int"
    assignmentNumericGrade: string;
    assignmentDuration: string;
    assignmentScore: string;
  };
}

export enum AdobeAnalyticsAction {
  ASSIGNMENT_CREATED = 'assignmentCreated',
  ASSIGNMENT_STARTED = 'assignmentStarted',
  ASSESSMENT_STARTED = 'assessmentStarted',
  ASSIGNMENT_COMPLETED = 'assignmentCompleted',
  ASSESSMENT_COMPLETED = 'assessmentCompleted',
}

export type AdobeAnalyticsActionProps = {
  action: AdobeAnalyticsAction.ASSIGNMENT_CREATED
    | AdobeAnalyticsAction.ASSIGNMENT_STARTED
    | AdobeAnalyticsAction.ASSIGNMENT_COMPLETED;
  pageData: {
    education: {
      assignmentId: string;
      assignmentName: string;
      assignmentType: string;
      beforeAfterDueDate: string;
    };
  };
} | {
  action: AdobeAnalyticsAction.ASSESSMENT_STARTED
    | AdobeAnalyticsAction.ASSESSMENT_COMPLETED;
  pageData: {
    education: {
      assignmentType: string;
    };
  };
}
