/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { RoutePath } from './app.constants';
import { ELSCommonUIConstants } from '../els.components';

export const AppRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.APP_LINK_REDIRECT,
      component: lazy(() => import(/* webpackChunkName: "AppLinkRedirect.page" */ '../../pages/app-link-redirect/AppLinkRedirect.page')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'ssa:inter-app-redirect',
        }
      }
    }, {
      path: RoutePath.SUPPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/support/Support.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ssa:support',
        }
      }
    },
    {
      path: RoutePath.REMEDIATION_SETTINGS,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/remediation-settings/RemediationSettings.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'ssa:remediation-settings',
        }
      }
    },

  ]
};
