export interface InstitutionDto {
  id: number;
  name: string;
  externalId: string;
}

export interface EntitlementDto {
  id: number;
  isbn: string;
  type: string;
  data: string;
  evolveProductTypeKey?: string;
  parentIsbn?: string;
  appId?: string;
}

export interface CourseSectionDto {
  id: number;
  courseId: string;
  externalId: string;
  lmsClientId: string;
  consumerKey: string;
  lmsExternalId: string;
  courseName: string;
  sectionName: string;
  duration: number;
  institution: InstitutionDto;
  courseActive: boolean;
  entitlements: EntitlementDto[];
  role: string;
  locked: boolean;
  copied: boolean;
  copiedFromId: boolean;
  migrated: boolean;
  gradeSyncVersion: number;
  createdAt: string | number;
  updatedAt: string | number;
  schedules?: unknown;
  users?: unknown;
}

export enum EvolveProductTypeKey {
  SHERPATH_IA = 'sherpath_ia',
  SHERPATH_BOOK_ORGANIZED = 'sherpath_book_organized',
  // Non sellable product used mainly to map EAQs to Sherpath courses
  // This name is confusing since it is used for EAQs but it is meant to be read from the EAQ app perspective
  SHERPATH_COMPONENT_NSS = 'sherpath_component_nss',
  // Non sellable ebook product - not sure why we use this ISBN instead of the vbId here
  SHERPATH_EBOOK_COMPONENT_NSS = 'sherpath_ebook_component_nss',
  SIMULATION_SIM_CHART_NG = 'simulation_sim_chart_ng',
  EAQNG_IA = 'eaqng_ia',
  EAQ_IA = 'eaq_ia',
  ELSEVIER_ASSESSMENT_BUILDER = 'elsevier_assessment_builder_ia',
  SHADOW_HEALTH = 'shadow_health',
  HESI_CASE_STUDY = 'case_study',
  HESI_PATIENT_REVIEW = 'patient_reviews',
  HESI_PRACTICE_TEST = 'practice_test',
  NURSING_CONCEPTS_IA = 'nursing_concepts_ia',
  HESI_ASSESSMENT_NEXTGEN = 'hesi_assessment_nextgen',
  SHERPATH_VANTAGE_IA = 'sherpath_vantage_ia',
  ELSEVIER_AI = 'elsevier_ai_ia',
}

export enum EvolveProductType {
  EBOOK = 'ebook',
  EPRODUCT = 'eproduct',
}

export interface EvolveProductDto {
  bundleMemberProduct: BundleMemberProductDto[]; // Related products mostly used in an upsell type scenario
  components: EvolveProductDto[]; // Bundled ISBNs under this product ISBN
  eeoISBN?: string;
  isbn: string;
  productTypeKey: EvolveProductTypeKey;
  productTypeName: string;
  title: string;
  type: EvolveProductType;
  vbID?: string; // VitalSource ebook ISBN (almost always same as print book ISBN but not always)
  vbId?: string; // There are cases where vbId has a lower case Id,
  realVantageComponent?: EvolveProductDto[];
}

export interface BundleMemberProductDto {
  isbn: string;
  productTypeKey: string;
  productTypeName: string;
  title: string;
}
