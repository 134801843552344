import {
  Component,
  ErrorInfo,
} from 'react';
import { Redirect } from 'react-router-dom';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { errorUtils } from '../../utilities/error.utilities';
import {
  AnalyticsAction,
} from '../../models/analytics.models';
import { trackAction } from '../app/app-state-actions.utilities';
import { DefaultAppState } from '../app/app-state.constants';

interface ErrorBoundaryState {
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<{ children }, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo
    });

    trackAction({
      appState: DefaultAppState,
      actionProps: {
        action: AnalyticsAction.JS_ERROR,
        props: {
          errorName: error ? error.name : null,
          errorMessage: error ? error.message : null,
          errorStack: errorInfo ? errorInfo.componentStack : null
        }
      }
    });

    errorUtils.logError({ err: error, previousState: errorInfo, previousStateParams: null });
  }

  render() {
    if (this.state.error) {
      if (this.state.error.name === 'InvalidTokenError') {
        return <Redirect to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />;
      }
      return (
        <div className="u-els-padding-3x u-els-text-center">
          <div>
            <ELSIcon name="alert-unexpected-error" size="4x" />
          </div>
          <h2>Oops, something went wrong.</h2>
          <div>
            <a href="/">Go Home</a>
          </div>
          <details className="u-els-text-left" style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
