import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { isEqual } from 'lodash';
import {
  ELSNewRelicBrowserService,
  ELSCommonConfig
} from '@els/els-ui-common-react';
import { getWordMark } from '../../utilities/app.utilities';

/**
 * This is a workaround while waiting the official release.
 * Helmet uses deep-equal and sometimes crashes on circular objects.
 * See: https://github.com/nfl/react-helmet/issues/373
 * The fix is in 6.x beta. Wait for official release
 */
Helmet.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps: object) {
  return !isEqual(this.props, nextProps);
};

export interface SEOData {
  title?: string;
}

const withHTMLHeadSEO = (data: SEOData = {}) => BaseComponent => {
  class SEOComponent extends PureComponent<{}> {
    render() {

      const headScripts = [];
      try {
        // eslint-disable-next-line no-undef
        const { licenseKey, applicationID, accountID, trustKey, agentID } = JSON.parse(getNewRelicBrowserConfig());
        if (licenseKey && applicationID && accountID && trustKey && agentID) {
          headScripts.push({
            type: 'text/javascript',
            innerHTML: ELSNewRelicBrowserService.registerNewRelicBrowser({ licenseKey, applicationID, accountID, trustKey, agentID })
          });
        }
      } catch (e) {
        ELSCommonConfig.getLogger().debug('New Relic Browser is not enabled for the current environment');
      }

      const pageNamePrefix = getWordMark();
      const title = data && data.title ? `${pageNamePrefix} - ${data.title}` : pageNamePrefix;
      return (
        <>
          <Helmet title={title} script={headScripts}>
            <html lang="en" />
          </Helmet>
          {BaseComponent && <BaseComponent {...this.props} />}
        </>
      );
    }
  }

  return SEOComponent;
};

export default withHTMLHeadSEO;
