// https://github.com/elsevierPTG/eols-config-property-store/blob/master/sherpath-course-management-service.yaml
export enum Application {
  EAQ = 'EAQ',
  EPM_SKILL = 'EPM_SKILL',
  KNOWLEDGECARD = 'KNOWLEDGECARD',
  PERFORMANCE = 'PERFORMANCE',
  SHER_EVOL = 'SHER_EVOL',
  SHERPATH = 'SHERPATH',
  SIMSNG = 'SIMSNG',
  HESI_NG = 'HESI_NG',
  HESI_LS= 'HESI_LS',
  SHADOW_HEALTH = 'SHADOW_HEALTH',
  NCO = 'NCO',
  AUTHESS = 'AUTHESS',
  EAB = 'EAB',
  STUDENT_STUDY = 'STUDENT_STUDY',
  HESI_REACT = 'HESI_REACT',
}

export enum AppAction {
  ASSESSMENT_GRADE_EDIT = 'ASSESSMENT_GRADE_EDIT',
  ASSESSMENT_PERFORMANCE_VIEW = 'ASSESSMENT_PERFORMANCE_VIEW',
  ASSESSMENT_START = 'ASSESSMENT_START',
  ASSIGNMENT_CREATE = 'ASSIGNMENT_CREATE',
  ASSIGNMENT_EDIT = 'ASSIGNMENT_EDIT',
  ASSIGNMENT_PERFORMANCE_VIEW = 'ASSIGNMENT_PERFORMANCE_VIEW',
  ASSIGNMENT_PREVIEW = 'ASSIGNMENT_PREVIEW',
  PERFORMANCE_VIEW = 'PERFORMANCE_VIEW',
  LAUNCH = 'LAUNCH',
}
