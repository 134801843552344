import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import {
  SystemType,
  CrosswalkUserDto,
  UserDto
} from './eols-user-management-service.dtos';

export const fetchUser = (userId: string): Promise<UserDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eolsUser/user/${userId}`);
  });
};

export const fetchCrosswalkUser = (userId: string, type: SystemType): Promise<CrosswalkUserDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eolsUser/${userId}/type/${type}/crosswalk`);
  });
};
