import { CourseSectionDto } from '../eols-course-crud/eols-course-crud.dtos';

export enum SystemType {
  EVOLVETYPE = 'evolve',
  VSTTYPE = 'vst'
}

export interface CrosswalkUserDto {
  eolsId: number;
  externalUserId: number;
  userName: string;
  type: string;
  userData: string;
  eolsUserIdsByUserNameAndType: number[];
}

export interface UserDto {
  courseSections: CourseSectionDto[];
  emailAddress: string;
  eolsUserHistory: {};
  eolsUserRoles: [];
  externalId: string;
  firstName: string;
  id: number;
  lastName: string;
  lastSeenDate: string;
  lmsExternalId: string;
  migrated: boolean;
  type: 'Ins' | 'Stu';
  updatedAt: string;
  username: string;
}
