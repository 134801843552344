import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import {
  isArray,
  isNil,
  isObject
} from 'lodash';

export const isInstructor = userRole => userRole === ELSCommonUIConstants.userType.Instructor;

export const isStudent = userRole => userRole === ELSCommonUIConstants.userType.Student;

export const flattenTree = (array, nestProp: string, level = 1) => {
  return array.reduce((acc, cur) => {
    if (cur[nestProp] && cur[nestProp].length) {
      return [...acc, cur, ...flattenTree(cur[nestProp], nestProp, level + 1)];
    }
    return [...acc, cur];
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stripNilProps = (obj: Record<string, any>): Record<string, any> => {
  const copy = {
    ...obj
  };
  Object.keys(copy).forEach((key) => {
    if (isNil(copy[key])) {
      delete copy[key];
    }
  });
  return copy;
};

export const isOnlyNumber = (str: string): boolean => {
  const pattern = /^[0-9]*$/gm;
  return pattern.test(str);
};

export const convertStringForClass = (string: string): string => {
  return string
    .toLowerCase()
    .replace(new RegExp(' ', 'g'), '-')
    .replace(new RegExp(/\/|\(|\)/g, 'g'), '');
};

export const mapToIds = (x) => x.id;

export const joinListCommaSeparated = (items: string[]): string => {
  if (!items || !items.length) {
    return '';
  }
  if (items.length === 1) {
    return items[0];
  }
  return items.reduce((acc, cur, idx, arr) => {
    if (idx === 0) {
      return cur;
    }
    if (idx + 1 === arr.length) {
      return `${acc} & ${cur}`;
    }
    return `${acc}, ${cur}`;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stitchArrays = (arrays: any[][], currentIndex = 0, output: any[] = []): any[] => {

  if (arrays.every((array) => {
    return !array.length;
  })) {
    return output;
  }

  const nextIndex = currentIndex + 1 === arrays.length ? 0 : currentIndex + 1;

  if (!arrays[currentIndex].length) {
    return stitchArrays(arrays, nextIndex, output);
  }

  const nextItem = arrays[currentIndex].shift();
  return stitchArrays(arrays, nextIndex, [...output, nextItem]);
};

export const truncateTitle = (title: string, maxLength: number): string => {
  if (isNil(maxLength) || !title || !title.length || title.length <= maxLength) {
    return title;
  }
  return `${title.substring(0, maxLength - 3)}...`;
};

export const getObjectPropsArray = (propVal, acc = [], path = ''): {
  prop: string;
  val: unknown;
}[] => {

  if (isArray(propVal)) {
    return propVal.reduce((_acc, cur, idx) => {
      return getObjectPropsArray(cur, _acc, path ? `${path}[${idx}]` : `[${idx}]`);
    }, acc);
  }

  if (!isObject(propVal)) {
    return [
      ...acc,
      {
        prop: path,
        val: propVal
      }
    ];
  }

  return Object.keys(propVal).reduce((_acc, prop) => {

    if (isArray(propVal[prop]) || isObject(propVal[prop])) {
      return getObjectPropsArray(propVal[prop], _acc, path ? `${path}.${prop}` : prop);
    }

    return [
      ..._acc,
      {
        prop: path ? `${path}.${prop}` : prop,
        val: propVal[prop]
      }
    ];
  }, acc);
};

export const filterBySearchQuery = (searchQuery: string, wordsToSearch: string[]): boolean => {
  if (!searchQuery || !searchQuery.length) {
    return true;
  }
  if (!wordsToSearch || !wordsToSearch.length) {
    return false;
  }
  const searchQueryWordList = searchQuery.toLowerCase().split(' ').map(item => item.trim());
  if (!searchQueryWordList || !searchQueryWordList.length) {
    return true;
  }
  const wordsToSearchNormalized = wordsToSearch.map(item => item.toLowerCase().trim());
  return searchQueryWordList.every(searchWord => {
    if (searchWord === '') {
      return true;
    }
    return wordsToSearchNormalized.some(word => {
      return word.includes(searchWord);
    });
  });
};

export const isTrueValue = (val: string): boolean => {
  if (isNil(val)) {
    return false;
  }
  return val.toString().toLowerCase() === 'true';
};

export const copyContentToClipboard = (content: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = content;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};
