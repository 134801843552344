import {
  useContext,
} from 'react';
import { useHistory } from 'react-router';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { AppContext } from '../app/AppProvider.component';
import { getIsMaintenance } from '../app/app-state-selectors.utilities';

export const MaintenanceHandlerComponent = () => {
  const {
    appState
  } = useContext(AppContext);

  const history = useHistory();
  const isMaintenanceMode = getIsMaintenance(appState);
  const maintenancePath = `/${ELSCommonUIConstants.security.States.Maintenance}`;

  if (isMaintenanceMode && history.location.pathname !== maintenancePath) {
    history.push(maintenancePath);
  }

  return null;
};

const MaintenanceHandler = MaintenanceHandlerComponent;
export default MaintenanceHandler;
