import {
  useContext,
  useEffect,
} from 'react';
import $ from 'jquery';
import {
  ELSTokenServiceRegistrar
} from '@els/els-ui-common-react';
import { useHistory } from 'react-router';
import { AppContext } from './AppProvider.component';
import { pickCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { RoutePath } from './app.constants';

export const AppRegistrarComponent = () => {

  const history = useHistory();

  const {
    setAppState
  } = useContext(AppContext);

  useEffect(() => {
    window.$ = $;
    window.jQuery = $;
    ELSTokenServiceRegistrar.initializeFromReload();
    const cookies = pickCookies();
    setAppState((prevState) => {
      return {
        ...prevState,
        appLinkCookies: cookies.linkId && cookies.token ? cookies : null,
        // cookieCheckComplete: true // TODO: Check this
      };
    });

    ELSTokenServiceRegistrar.registerTokenTimeoutCallback(() => {
      history.push(RoutePath.TIMEOUT);
    });
  }, []);

  return null;
};

const AppRegistrar = AppRegistrarComponent;
export default AppRegistrar;
