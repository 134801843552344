import {
  ELSCommonConfig,
  ELSTokenHelper
} from '@els/els-ui-common-react';
import { get } from 'lodash';
import { convertIsbnsToNormalizedString } from '../../utilities/app.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import {
  FALSE_VALUE,
  FEATURE_FLAG,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import { UNKNOWN } from './app.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { AppState } from './app-state.types';

export const getIsbns = (state: AppState): string[] => {
  if (!state.isbns) {
    return null;
  }
  return state.isbns.split(',');
};

const getUserEmailDomain = (appState: AppState): string => {
  const user = appState.eolsUser;
  const emailAddress = !user || !user.emailAddress ? ELSTokenHelper.getUserEmail() : user.emailAddress;
  if (!emailAddress) {
    return UNKNOWN;
  }
  const emailParts = emailAddress.split('@');
  if (!emailParts || emailParts.length !== 2) {
    return UNKNOWN;
  }
  return emailParts[1];
};

const isTestUser = (appState: AppState): string => {
  const emailDomain = getUserEmailDomain(appState);
  if (emailDomain === UNKNOWN) {
    return UNKNOWN;
  }

  const _isTestUser = ELSCommonConfig.testUserEmailDomains.some((domain) => {
    return emailDomain.includes(domain);
  });
  return _isTestUser ? TRUE_VALUE : FALSE_VALUE;
};

const getConsumerKey = (appState: AppState) => {
  return get(appState, 'currentCourse.consumerKey', '');
};
export const getDefaultActionProps = (appState: AppState): Record<string, string | number | string[]> => {
  const { evolveUser } = appState;
  const institution = appState.currentCourse ? appState.currentCourse.institution : null;
  const props = {
    courseSectionId: appState.courseSectionId,
    eolsUserId: appState.userId,
    evolveUserId: evolveUser ? evolveUser.externalUserId : UNKNOWN,
    userRole: appState.userRole,
    isbns: appState.isbns,
    isbnsSorted: convertIsbnsToNormalizedString(getIsbns(appState)),
    institution: institution ? institution.name : null,
    userEmailDomain: getUserEmailDomain(appState),
    isTestUser: isTestUser(appState),
    isMasqueradeUser: ELSTokenHelper.isMasqueradeUser(),
    pathname: window.location.pathname,
    appVersion: window.getBuildVersion ? window.getBuildVersion() : null,
    consumerKey: getConsumerKey(appState)
  };
  return scrubProps(props);
};

export const getIsMaintenance = (appState: AppState): boolean => {
  const value = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    appState.featureFlagsGrouped,
    FEATURE_FLAG.IS_SITE_DOWN_FOR_MAINTENANCE,
    appState.courseSectionId
  );
  return value === null ? false : value;
};
