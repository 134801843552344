import {
  defineMessages,
  MessageDescriptor
} from 'react-intl';

export enum LANGUAGE_KEY {
  TEST = 'TEST',
}

const messages = Object.keys(LANGUAGE_KEY).reduce((acc, cur) => ({
  ...acc,
  [cur]: {
    id: cur,
    defaultMessage: cur,
  }
}), {}) as Record<LANGUAGE_KEY, MessageDescriptor>;

export const Translations = defineMessages<LANGUAGE_KEY>(messages);

// Define list of static messages that can be translated without passing any value in
export const StaticMessagesKeys: LANGUAGE_KEY[] = [
  LANGUAGE_KEY.TEST,
];
