import React, {
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  ELSCommonConfig,
  ELSStorageHelper
} from '@els/els-ui-common-react';
import { noop } from 'lodash';
import { PERSIST_KEY } from './app.constants';
import { AppState } from './app-state.types';
import { DefaultAppState } from './app-state.constants';

type AppProviderProps = {
  children: React.ReactNode;
}

export type AppContextState = {
  appState: AppState;
  setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

const defaultContextValue: AppContextState = {
  appState: DefaultAppState,
  setAppState: noop,
};

export const AppContext: React.Context<AppContextState> = createContext(defaultContextValue);

const AppProvider = ({ children }: AppProviderProps) => {

  const storedAppState = ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
    .namespace('')
    .get(PERSIST_KEY);

  const [appState, setAppState] = useState({
    ...DefaultAppState,
    ...storedAppState
  });

  useEffect(() => {
    ELSStorageHelper.driver(ELSCommonConfig.storageDriver)
      .namespace('')
      .put(PERSIST_KEY, appState);
  }, [appState]);

  const contextValue = useMemo(() => ({
    appState,
    setAppState,
  }), [
    appState,
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
