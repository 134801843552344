export enum RoutePath {
  APP_LINK_REDIRECT = '/redirect',
  TIMEOUT = '/timedout',
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  PAGE_NOT_FOUND = '/pageNotFound',
  SUPPORT = '/support',
  HELP = '/help',
  REMEDIATION_SETTINGS = '/remediation-settings',
}

export const ServicePath = {
  loginPath: '/login-service/login',
  tokenServicePath: '/token-service',
  userServicePath: '/apiv1/eolsUser/list',
};

export const AppConstants = {
  APP_ID: 'HESI_REACT',
  IDLE_TIMEOUT: 1000 * 60 * 60 * 6,
  PENDO_API_KEY: '' // '94bb3f52-df30-4f69-4ec8-dceb6bffffbe'
};

export const UNKNOWN = 'UNKNOWN';
export const PERSIST_KEY = 'globalAppState';
