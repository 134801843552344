import {
  useContext,
  useEffect,
} from 'react';
import {
  injectIntl,
  IntlShape
} from 'react-intl';
import { compose } from 'recompose';
import { AppContext } from '../app/AppProvider.component';
import { translateMessages } from '../../translations/message.utilities';
import { StaticMessagesKeys } from '../../translations/message.constants';

export const MessagesRegistrarComponent = (props: {
  intl: IntlShape;
}) => {
  const {
    setAppState
  } = useContext(AppContext);

  useEffect(() => {
    const translatedMessages = translateMessages(StaticMessagesKeys, props.intl);
    setAppState((prevState) => {
      return {
        ...prevState,
        messages: translatedMessages
      };
    });
  }, []);

  return null;
};

const enhancers = [
  injectIntl,
];

const MessagesRegistrar = compose<null, {}>(...enhancers)(MessagesRegistrarComponent);
export default MessagesRegistrar;
