import React, {
  Suspense,
} from 'react';
import {
  Redirect,
  Router,
  Switch
} from 'react-router-dom';
import { createHashHistory } from 'history';
import {
  ELSSecurityRoutes,
  ELSIdleProvider,
  ELSCommonUIConstants,
  ELSPageLoader,
  ELSRouterHelper,
  ELSAccessibilityFocusState,
} from '@els/els-ui-common-react';
import { ELSModalProvider } from '@els/els-component-modal-react';
import {
  IntlProvider,
} from 'react-intl';
import {
  setDefaultAppConfig
} from './app.config';
import ErrorBoundary from '../error-boundary/ErrorBoundary.component';
import LocationChangeHandler from '../location-change-handler/LocationChangeHandler.component';
import { AppRoutes } from './app.routes';
import {
  AppConstants,
  RoutePath
} from './app.constants';
import '../../assets/main.scss';
import {
  getLanguage,
  messages
} from '../../utilities/app.utilities';
import AppProvider from './AppProvider.component';
import withHTMLHeadSEO from '../../hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import AnalyticsRegistrar from '../analytics-registrar/AnalyticsRegistrar.component';
import MessagesRegistrar from '../messages-registrar/MessagesRegistrar.component';
import AppRegistrar from './AppRegistrar.component';
import MaintenanceHandler from '../maintenance-handler/MaintenanceHandler.component';

setDefaultAppConfig();
const history = createHashHistory<{}>();
const HTMLHeadSEOComponent = withHTMLHeadSEO(null)(null);
const App = () => {
  return (
    <AppProvider>
      <IntlProvider locale={getLanguage()} messages={messages[getLanguage()]}>
        <ELSModalProvider>
          <ErrorBoundary>
            <Router history={history}>
              <AppRegistrar />
              <HTMLHeadSEOComponent />
              <AnalyticsRegistrar />
              <MessagesRegistrar />
              <LocationChangeHandler />
              <MaintenanceHandler />
              <Suspense fallback={<ELSPageLoader />}>
                <Switch>
                  {ELSSecurityRoutes.getSecurityRoutes()
                    .filter((route) => {
                      return route.path !== `/${ELSCommonUIConstants.security.States.Admin}`;
                    })
                    .map(route => {
                      return ELSRouterHelper.createRoute(route);
                    })}
                  {AppRoutes.getRoutes()
                    .map((route) => ELSRouterHelper.createRoute(route))}
                  <Redirect from="/" exact to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />
                  <Redirect to={`/${ELSCommonUIConstants.security.States.PageNotFound}`} />
                </Switch>
              </Suspense>
            </Router>
            <ELSAccessibilityFocusState />
            <ELSIdleProvider
              timeout={AppConstants.IDLE_TIMEOUT}
              onSessionTimeout={() => {
                history.push(RoutePath.TIMEOUT);
              }}
            />
          </ErrorBoundary>
        </ELSModalProvider>
      </IntlProvider>
    </AppProvider>
  );
};

export default App;
