export const NO_VALUE = 'NO_VALUE';
export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

// TODO: Describe feature flags
export enum FEATURE_FLAG {
  ADOBE_ANALYTICS_DISABLED = 'ADOBE_ANALYTICS_DISABLED',
  IS_SITE_DOWN_FOR_MAINTENANCE = 'IS_SITE_DOWN_FOR_MAINTENANCE',
  EXAMPLE_FEATURE_FLAG = 'EXAMPLE_FEATURE_FLAG',
}

export const AB_TEST = 'AB_TEST';

export const AB_TEST_CONFIG_MAP: Partial<Record<FEATURE_FLAG, string[]>> = {
  [FEATURE_FLAG.EXAMPLE_FEATURE_FLAG]: [
    'EXAMPLE_FLAVOR_1',
    'EXAMPLE_FLAVOR_2',
  ],
};
