import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import {
  get,
} from 'lodash';
import {
  ELSPendoService,
  ELSTokenHelper,
} from '@els/els-ui-common-react';
import {
  ELSAnalyticsRegistrarComponent,
  ELSPerformanceAnalyticsRegistrarProps
} from '@els/els-component-shared-ts-react';
import { AppConstants } from '../app/app.constants';
import {
  convertIsbnsToNormalizedString,
  getApplicationContext
} from '../../utilities/app.utilities';
import {
  isInstructor,
  isStudent
} from '../../utilities/common.utilities';
import { AppContext } from '../app/AppProvider.component';
import { getIsbns } from '../app/app-state-selectors.utilities';

export type AnalyticsRegistrarState = {
  pendoScriptInjected: boolean;
}

const defaultState: AnalyticsRegistrarState = {
  pendoScriptInjected: false
};

export const AnalyticsRegistrarComponent = () => {
  const [state, setState] = useState<AnalyticsRegistrarState>(defaultState);

  const {
    appState,
  } = useContext(AppContext);

  useEffect(() => {
    if (!state.pendoScriptInjected) {
      ELSPendoService.injectScript(AppConstants.PENDO_API_KEY).then(() => {
        setState({ pendoScriptInjected: true });
      });
    }
  }, []);

  const getEvolveUserEmail = () => {
    const { evolveUser } = appState;
    if (!evolveUser) {
      return null;
    }
    const evolveUserData = JSON.parse(evolveUser.userData);
    if (!evolveUserData || !evolveUserData.email) {
      return null;
    }
    return evolveUserData.email;
  };

  const {
    isbns,
    userRole,
    currentCourse,
    userId,
    evolveUser
  } = appState;

  if (!isbns || !currentCourse || !state.pendoScriptInjected) {
    return <></>;
  }

  if (!evolveUser) {
    return <></>;
  }

  const institution = currentCourse ? currentCourse.institution : null;
  const courseName = currentCourse ? currentCourse.courseName : null;

  const props: ELSPerformanceAnalyticsRegistrarProps = {
    isbn: convertIsbnsToNormalizedString(getIsbns(appState)),
    userRole,
    appName: 'HESI_REACT',
    productName: get(window, 'pageData.page.productName', ''),
    businessUnit: get(window, 'pageData.page.businessUnit', ''),
    visitorId: null,
    pageEnvironment: get(window, 'pageData.page.environment', ''),
    courseId: currentCourse.id.toString(),
    accountName: institution ? institution.name : null,
    accountId: institution ? institution.id.toString() : null,
    evolveUserId: evolveUser ? evolveUser.userName.toString() : null,
    eolsUserId: userId,
    shouldInjectPendoScript: false,
    eolsUserEmail: ELSTokenHelper.getUserEmail(),
    evolveUserEmail: getEvolveUserEmail(),
    isStudent: isStudent(userRole),
    isInstructor: isInstructor(userRole),
    isIncludeAdobeEducationObject: true,
    courseName,
    departmentId: '',
    departmentName: '',
    application: getApplicationContext()
  };

  return <ELSAnalyticsRegistrarComponent {...props} />;
};

const AnalyticsRegistrar = AnalyticsRegistrarComponent;

export default AnalyticsRegistrar;
